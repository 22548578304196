<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            size="sm"
            :href="downloadURL"
            target="_blank"
          >
            <feather-icon icon="PrinterIcon" /> Kolisaj Yazdır
          </b-button>
          <b-button
            variant="success"
            class="mr-1"
            :to="'/sparepart-orders/import/'+dataItem.id"
            size="sm"
          >
            <feather-icon icon="UploadIcon" /> Excel Yükle
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="newRow"
          >
            <feather-icon icon="PlusIcon" /> Ekle
          </b-button>
        </div>
      </b-card-body>
      <b-row v-if="!dataLoading && dataList.length < 1">
        <b-col>
          <b-alert
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Girilmiş sipariş detayı bulunmamakta.
            </div>
            <div class="text-center">
              <b-button
                variant="primary"
                size="sm"
                class="m-1"
                @click="newRow"
              >
                <feather-icon icon="PlusIcon" />Ekle
              </b-button>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-table
        v-else
        responsive
        small
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(part_title)="data">
          <div v-if="data.item.order_no">
            <small class="text-warning">{{ data.item.order_no }}</small>
          </div>
          {{ data.item.part_title }}
          <div
            v-if="data.item.quantity"
          >
            <b-badge variant="light-primary">
              {{ data.item.quantity }} Adet
            </b-badge>
          </div>
        </template>
        <template #cell(order_date)="data">
          {{ moment(data.item.order_date).format('DD.MM.YYYY') }}
        </template>
        <template #cell(arrival_date)="data">
          {{ moment(data.item.arrival_date).format('DD.MM.YYYY') }}
          <div v-if="data.item.completed_date">
            <b-badge variant="light-success">
              <feather-icon icon="CheckIcon" />
              {{ moment(data.item.completed_date).format('DD.MM.YYYY') }}
            </b-badge>
          </div>
        </template>
        <template #cell(quantity)="data">
          <div v-if="data.item.list_price">
            {{ data.item.list_price | toCurrency }} TL
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            variant="outline-primary"
            size="sm"
            class="mr-1"
            @click="editRow(data.item.id)"
          >
            Düzenle
          </b-button>
          <b-button
            v-if="userData.id_com_user_type === '1'"
            variant="flat-danger"
            size="sm"
            class="btn btn-icon"
            @click="removeRow(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="modalData.status"
      title="Sipariş Detayı"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <sparepart-order-modal />
      <template #modal-footer>
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="addStockCard"
          >
            <FeatherIcon icon="PlusIcon" />
            Yeni Stok Kartı
          </b-button>
          <b-button
            variant="success"
            :disabled="saveLineLoading"
            @click="saveLine"
          >
            <b-spinner
              v-if="saveLineLoading"
              small
            />
            <feather-icon
              v-if="!saveLineLoading"
              icon="SaveIcon"
            /> Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="stockCardModal"
      title="Stok Kartı Tanımla"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <sparepart-stock-cards-item-form />
      <template #modal-footer>
        <b-button
          variant="success"
          @click="saveCardAndCallback"
        >
          <feather-icon
            icon="SaveIcon"
          /> Stok Kartı Kaydet
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BAlert, BTable, BModal, BCardBody, BSpinner, BBadge,
} from 'bootstrap-vue'

import SparepartOrderModal from '@/views/Spareparts/Orders/Components/OrderModal.vue'
import SparepartStockCardsItemForm from '@/views/Spareparts/StockCards/ItemForm.vue'

export default {
  name: 'SparepartOrderLines',
  components: {
    SparepartStockCardsItemForm,
    SparepartOrderModal,
    BTable,
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BCardBody,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      stockCardModal: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      saveLineLoading: false,
      modalData: {
        status: false,
        index: null,
      },
      fields: [
        {
          key: 'part_title',
          label: 'Parça',
        },
        {
          key: 'order_date',
          label: 'Sipariş Tarihi',
        },
        {
          key: 'arrival_date',
          label: 'Tahmini Geliş Tarihi',
        },
        {
          key: 'quantity',
          label: 'Birim Fiyat',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '150px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['sparepartOrderLines/dataList']
    },
    dataLoading() {
      return this.$store.getters['sparepartOrderLines/dataLoading']
    },
    lineData() {
      return this.$store.getters['sparepartOrderLines/dataItem']
    },
    newStockCardForm() {
      return this.$store.getters['sparepartStockCards/dataItem']
    },
    downloadURL() {
      const urlParams = [
        `&id=${this.dataItem.id}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/spareparts/kolisaj/pdf?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    addStockCard() {
      this.stockCardModal = true
    },
    getDataList() {
      this.$store.dispatch('sparepartOrderLines/getDataList', {
        where: {
          'com_sparepart_order_lines.id_com_sparepart_orders': this.$route.params.id,
        },
      })
    },
    newRow() {
      this.modalData.status = true
      this.$store.dispatch('sparepartOrderLines/resetDataItem')
    },
    editRow(index) {
      this.modalData.status = true
      this.$store.dispatch('sparepartOrderLines/getDataItem', index)
    },
    saveLine() {
      this.saveLineLoading = true
      this.lineData.id_com_sparepart_orders = this.dataItem.id
      this.$store.dispatch('sparepartOrderLines/saveData', this.lineData)
        .then(response => {
          if (response) {
            this.modalData.status = false
            this.getDataList()
            this.saveLineLoading = false
          }
        })
    },
    removeRow(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('sparepartOrderLines/deleteData', id)
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    saveCardAndCallback() {
      this.$store.dispatch('sparepartStockCards/saveData', this.newStockCardForm)
    },
  },
}
</script>
