<template>
  <div v-if="id_com_cars">
    <b-list-group>
      <template v-if="car.brand && car.model">
        <list-item
          v-if="car.brand"
          title="Marka"
          :value="car.brand"
        />
        <list-item
          v-if="car.model"
          title="Model"
          :value="car.model"
        />
      </template>
      <template v-else>
        <list-item
          v-if="car.swap_brand"
          title="Marka"
          :value="car.swap_brand"
        />
        <list-item
          v-if="car.swap_model"
          title="Model"
          :value="car.swap_model"
        />
      </template>
      <list-item
        v-if="car.hardware"
        title="Donanım Paketi"
        :value="car.hardware"
      />
      <list-item
        v-if="car.myear"
        title="Model Yılı"
        :value="car.myear"
      />
      <list-item
        v-if="car.color"
        title="Renk"
        :value="car.color"
      />
      <list-item
        v-if="car.fuel"
        title="Yakıt Tipi"
        :value="car.fuel"
      />
      <list-item
        v-if="car.gear"
        title="Vites Tipi"
        :value="car.gear"
      />
      <list-item
        v-if="car.license_plate"
        title="Plaka"
        :value="car.license_plate"
      />
      <list-item
        title="Şase No"
        :value="car.chassis? car.chassis : '-'"
      />
    </b-list-group>
    <div
      v-if="id_com_cars"
      class="mt-2 text-center"
    >
      <b-button
        v-b-toggle.sidebar-invoice-add-new-car
        variant="primary"
      >
        Bilgileri Güncelle
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BButton, VBToggle,
} from 'bootstrap-vue'
import ListItem from '@/views/Crm/View/ListItem.vue'

export default {
  name: 'CarCard',
  components: {
    BListGroup,
    BButton,
    ListItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    id_com_cars: {
      type: String,
      default: null,
    },
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
  watch: {
    id_com_cars(val) {
      if (val) {
        this.getCar()
      } else {
        this.$store.dispatch('cars/carReset')
      }
    },
  },
  created() {
    this.getCar()
  },
  methods: {
    getCar() {
      if (this.id_com_cars) {
        this.$store.dispatch('cars/carView', this.id_com_cars)
          .then(response => {
            if (response.id_com_swap_brand) {
              this.$store.dispatch('swapmodels/swapmodelsList', {
                where: {
                  'com_swap_model.id_com_swap_brand': response.id_com_swap_brand,
                },
              })
            }
          })
      }
    },
  },
}
</script>
